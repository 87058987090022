import React, {useContext, useState} from 'react';
// useParams hook
import {useParams} from 'react-router-dom';
// useFecth hook
import useFetch from '../hooks/useFetch';
// components
import RelatedProducts from '../components/RelatedProducts';
// icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// context
import {CartContext} from '../context/CartContext';
import ThemedSuspense from "../components/ThemedSuspense";

const ProductDetails = () => {
    const {addToCart} = useContext(CartContext);
    const {id} = useParams();

    const {data} = useFetch(`/products/${id}`);
    const rate = useFetch('/rates');

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    if (!data) {
        return <div>
            <ThemedSuspense/>
        </div>;
    }
    // category title
    const categoryTitle = data.category.category_name;
    const description = showFullDescription ? data.description : `${data.description.slice(0, 100)}...`;

    const kurs = rate.data && rate.data.length > 0 ? rate.data[0].rate : null;

    return (
        <div className='mb-16 pt-44 lg:pt-[30px] xl:pt-0'>
            <div className='container mx-auto'>
                {/* text */}
                <div className='flex flex-col lg:flex-row gap-[30px] mb-[30px]'>
                    <div
                        className='flex-1 lg:max-w-[40%] lg:h-[540px] grad rounded-lg flex justify-center items-center'>
                        <img
                            src={`https://api.max-photo.uz` + data.thumbnail}
                            alt=''
                            className='w-full max-w-[65%]'
                        />
                    </div>
                    <div className='flex-1 bg-primary p-12 xl:p-20 rounded-lg flex flex-col justify-center'>
                        {/* category title */}
                        <div className='uppercase text-accent text-lg font-medium mb-2'>
                            {data.category.category_name}
                        </div>
                        {/* title */}
                        <h2 className='h2 mb-4'>{data.name}</h2>
                        {/* description */}
                        <p className='mb-12'>{description + '  '}
                            <b>
                                {data.description.length > 100 && (
                                    <button className="text-accent" onClick={toggleDescription}>
                                        {showFullDescription ? <FaChevronUp /> : <FaChevronDown />}
                                    </button>
                                )}
                            </b>
                        </p>
                        {/* price & btn */}
                        <div className='space-y-5 justify-center gap-x-8'>
                            <div className='text-3xl text-accent font-semibold'>
                                {(data?.price * kurs).toLocaleString('ru-RU')} UZS
                            </div>
                            <div className='text-3xl text-accent font-semibold'>
                                ${data.price}
                            </div>
                            <button
                                onClick={() => addToCart(data, id)}
                                className='btn btn-accent text-white'
                            >
                                Добавить в корзину
                            </button>
                        </div>
                    </div>
                </div>
                {/* related products */}
                <RelatedProducts categoryTitle={categoryTitle}/>
            </div>
        </div>
    );
};

export default ProductDetails;
