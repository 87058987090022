// link
import {Link} from 'react-router-dom';
import ProductSkeleton from "./ProductSkeleton";
import useFetch from "../hooks/useFetch";

const Product = ({product, data}) => {

    return (
        !product ? <ProductSkeleton/> :
            <Link to={`/product/${product.id}`}>
                <div className='grad shadow-2xl w-full h-[362px] rounded-[8px] overflow-hidden relative group'>
                    {/* badge */}
                    {product.brand ? (
                        <div
                            className='absolute bg-accent text-white text-[12px] font-extrabold uppercase top-4 right-4 px-2 rounded-full z-10'>
                            {product.brand}
                        </div>
                    ) : (
                        ''
                    )}
                    {/* image */}
                    <div className='w-full h-[200px] flex items-center justify-center relative'>
                        <img
                            className='w-[160px] h-[120px] group-hover:scale-90 transition-all'
                            src={product.thumbnail}
                            alt=''
                        />
                    </div>
                    {/* text */}
                    <div className='px-6 pb-8 flex flex-col'>
                        {/* category title */}
                        <div className='text-sm text-accent capitalize mb-2'>
                            {product.category.category_name}
                        </div>
                        {/* title */}
                        <div className='text-[15px] mb-4 lg:mb-9'>
                            {product.name.substring(0, 35)}...
                        </div>
                        {/* price */}
                        <div className='text-lg text-accent mb-1'>
                            <span className="bg-gray-200 px-1 shadow-2xl rounded-md">
                                ${product.price}
                            </span>
                        </div>
                        {data?.length > 0 ?
                            <div className="text-sm text-gray-200">
                                {(product.price * data[0].rate).toLocaleString('ru-RU')} UZS
                            </div> : <></>}
                    </div>
                </div>
            </Link>
    );
};

export default Product;
