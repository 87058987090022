/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// useParams hook
import { useParams } from "react-router-dom";
// useFecth hook
import useFetch from "../hooks/useFetch";
// components
import CategoryNav from "../components/CategoryNav";
import Product from "../components/Product";

const Products = () => {
  const { id: categoryId } = useParams();
  const [title, setTitle] = useState("");

  // get products based on category id
  const { data } = useFetch(`/products`);


  const filteredProducts = data?.filter(
    (product) => product.category.id === Number(categoryId)
  );

  useEffect(() => {
    if (data) {
      const findProduct = data.find(
        (product) => product.category.id === Number(categoryId)
      );
      if (findProduct && findProduct.category) {
        setTitle(findProduct.category.category_name);
      } else {
        setTitle("Нет товаров");
      }
    }
  }, [data, categoryId]);

  return (
    <div className="mb-16 pt-24 lg:pt-0">
      <div className="container mx-auto">
        <div className="flex flex-col gap-x-[30px]">
          <CategoryNav />
          <main>
            <div className="py-3 text-xl uppercase text-center lg:text-left">
              {title}
            </div>
            {/* product grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-[15px] md:gap-[30px]">
              {filteredProducts &&
                filteredProducts.map((product) => (
                  <Product product={product} key={product.id} />
                ))}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Products;
