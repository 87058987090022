/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { request } from "../request";
import { CartContext } from "../context/CartContext";
import { useNavigate } from "react-router-dom";

const Order = () => {
  const { error, setCart, cart } = useContext(CartContext);
  const phoneNumberRegex = /^(\+998|998)?[7-9][0-9]{8}$/;
  const [phoneNumber, setPhoneNumber] = useState("+998");
  const [validNumber, setValidNumber] = useState(false);
  const [focusNumber, setFocusNumber] = useState(false);
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
  });
  const [products, setProducts] = useState([]);
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const result = phoneNumberRegex.test(phoneNumber);
    setValidNumber(result);
  }, [phoneNumber]);

  useEffect(() => {
    if (cart.length) {
      const updatedProducts = cart.map((item) => ({
        count: item.amount,
        id: item.id,
        info: {
          id: item.id,
          category: {
            id: item.category.id,
            category_name: item.category.category_name,
            image: item.category.image,
          },
          color: {
            id: item.color.id,
            color_code: item.color.color_code,
            color_name: item.color.color_name,
          },
          name: item.name,
          brand: item.brand,
          description: item.description,
          price: item.price,
          thumbnail: item.thumbnail,
          stock: item.stock,
          created_at: item.created_at,
          updated_at: item.updated_at,
        },
      }));
      setProducts(updatedProducts);
    }else {
      setProducts([])
    }
  }, [cart]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (error >= 1) {
        const req = await request.post("/cart/", {
          firstname: formData.firstName,
          lastname: formData.lastName,
          phone_number: phoneNumber,
          address: formData.address,
          products: products,
        });
        if (req.status === 200) {
          setSuccess(
            "Ваш заказ принят, мы свяжемся с вами в ближайшее время!"
          );
          setFormData({
            firstName: "",
            lastName: "",
            address: "",
          });
          setPhoneNumber("+998")
          setCart([]);
          setTimeout(() => {
            navigate("/", {replace: true})
          }, 1500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setInterval(() => {
      setSuccess("");
    }, 3000);
  }, [success]);

  return (
    <div className="container py-20 mx-auto md:mt-auto p-10 mt-32">
      <div className="mx-auto w-9/12 md:w-max mb-8 -mt-5 md:text-2xl text-xl text-green-400 text-center">
        {success}
      </div>
      <div className="mx-auto w-9/12 md:w-max mb-8 -mt-5 md:text-2xl text-xl text-red-600 text-center">
        {error >= 1 ? "" : "Сначала выберите товар!"}
      </div>

      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-200"
          >
            Имя
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="order-input mt-1 p-2 border text-primary rounded-md w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-200"
          >
            Фамилия
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="order-input mt-1 p-2 border text-primary rounded-md w-full"
            required
          />
        </div>
        {/* Repeat similar structure for other form fields */}

        <div className="col-span-2 mb-4">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-200"
          >
            Адрес
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="order-input mt-1 p-2 border text-primary rounded-md w-full"
            required
          />
        </div>

        <div className="col-span-2 mb-4">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-200"
          >
            Номер телефона
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onFocus={() => setFocusNumber(true)}
            onBlur={() => setFocusNumber(false)}
            className="order-input mt-1 p-2 border text-primary rounded-md w-full"
            required
          />
        </div>
        {/* Other form fields */}

        <div className="col-span-2 mb-4">
          <button
            type="submit"
            className={`btn ${validNumber ? 'btn-accent' : 'disabled: btn btn-accent opacity-50'} text-white mx-auto lg:mx-0`}
            disabled={!validNumber}
          >
            Заказать
          </button>
        </div>
      </form>
    </div>
  );
};

export default Order;
