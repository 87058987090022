import React, { useEffect, useState } from 'react';
// icons
import { FiSearch } from 'react-icons/fi';
// useNavigate hook
import { useNavigate } from 'react-router-dom';

const SearchForm = ({ setCatnavMobile }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
    // clear timeout event
    return () => clearTimeout(timeout);
  }, []);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.length > 0) {
      navigate(`/search?query=${searchTerm}`);
      document.querySelector('input').value = '';
      setSearchTerm('');
    } else {
      setIsAnimating(true);
    }
    setCatnavMobile(false);
  };

  return (
      <form
          onSubmit={handleSubmit}
          className={`${isAnimating ? 'animate-shake' : 'animate-none'} w-full relative`}
      >
        <input
            onChange={handleSearchInput}
            className='input bg-body text-gray-400'
            type='text'
            placeholder='Поиск товаров...'
        />
        <button className='btn btn-accent absolute top-0 right-0 rounded-tl-none rounded-bl-none'>
          <FiSearch className='text-xl text-white' />
        </button>
      </form>
  );
};

export default SearchForm;
