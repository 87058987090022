import React, {useContext, useEffect, useRef} from 'react';
// icons
import {IoArrowForward, IoCartOutline, IoClose} from "react-icons/io5";
// context
import {CartContext} from "../context/CartContext";
// components
import CartItem from './CartItem';
// stripe
// import {loadStripe} from '@stripe/stripe-js';
import {request} from '../request';
import {Link} from "react-router-dom";

const Cart = () => {
    const {setIsOpen, cart, total, clearCart} = useContext(CartContext);
    const closeDivRef = useRef(null);

    // const handleOrder = async (e) => {
    //   e.preventDefault()
    //     try {
    //         // const stripe = await stripePromise;
    //         const req = await request.put('/cart/', {
    //             cart,
    //         });
    //         console.log(req);
    //         // await stripe.redirectToCheckout({
    //         //   sessionId: res.data.stripeSession.id,
    //         // });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeDivRef.current && !closeDivRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full h-full px-4 text-white" ref={closeDivRef}>
            <div className="overflow-y-auto overflow-x-hidden h-[75vh]">
                {/* close icon */}
                <div
                    onClick={() => setIsOpen(false)}
                    className="text-4xl w-20 h-[98px] flex justify-start items-center cursor-pointer"
                >
                    <IoClose/>
                </div>
                <div className="flex flex-col gap-y-10 px-2">
                    {cart.map((item) => {
                        return <CartItem item={item} key={item.id}/>;
                    })}
                </div>
            </div>
            {/* subtotal & total */}
            {cart.length >= 1 && (
                <div className="px-6 py-10 flex flex-col">
                    {/* subtotal */}
                    <div className="flex justify-between text-lg">
                        <div>Итого</div>
                        <div>$ {total}</div>
                    </div>
                </div>
            )}

            {/* buttons */}
            <div className="px-6">
                {cart.length >= 1 ? (
                    <div className="flex justify-between gap-x-4">
                        <button
                            onClick={clearCart}
                            className="btn btn-accent hover:bg-accent-hover text-white"
                        >
                            Очистить корзину
                        </button>
                        <Link to={"/order"}>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="btn btn-accent text-white hover:bg-accent-hover flex-1 px-2 gap-x-2"
                            >
                                Оформление заказа
                                <IoArrowForward className="text-lg"/>
                            </button>
                        </Link>
                    </div>
                ) : (
                    <div
                        className="h-full absolute top-0 right-0 left-0 flex justify-center items-center -z-10 flex-col text-white/30">
                        <div className="text-2xl">Ваша корзина пуста</div>
                        <div className="text-6xl">
                            <IoCartOutline/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
