/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import { request } from "../request";

// create context
export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(0);

  // cart amount
  useEffect(() => {
    const amount = cart.reduce((a, c) => {
      return a + c.amount;
    }, 0);

    setItemsAmount(amount);
    setTimeout(() => {
      setError(amount);
    }, 2000);
  }, [cart]);

  // cart total
  useEffect(() => {
    const total = cart.reduce((a, c) => {
      return a + c.price * c.amount;
    }, 0);
    setTotal(total);
  }, [cart]);

  // add to cart
  const addToCart = (item, id) => {
    setCart((prevCart) => {
      const itemID = parseInt(id);
      const newItem = { ...item, amount: 1 };
      const cartItem = prevCart.find((item) => item.id === itemID);

      if (cartItem) {
        // Item is already in the cart
        return prevCart.map((item) => {
          if (item.id === itemID) {
            // If amount is 0, increase it to 1; otherwise, leave it unchanged
            const newAmount = item.amount === 0 ? 1 : item.amount;
            return { ...item, amount: newAmount };
          } else {
            return item;
          }
        });
      } else {
        return [...prevCart, newItem];
      }
    });

    // Open the cart sidebar
    setIsOpen(true);
  };

  // remove from cart
  const removeFromCart = (id) => {
    const data = {
      product_id: id,
    };
    request.delete("/cart", data).then((response) => {
      console.log(response);
    });
    const newCart = cart.filter((item) => {
      return item.id !== id;
    });
    setCart(newCart);
  };

  // handle input
  const handleInput = (e, id) => {
    const value = parseInt(e.target.value);
    // find the item in the cart by id
    const cartItem = cart.find((item) => {
      return item.id === id;
    });

    if (cartItem) {
      const newCart = cart.map((item) => {
        if (item.id === id) {
          if (isNaN(value)) {
            setAmount(1);
            return { ...item, amount: 1 };
          } else {
            setAmount(value);
            return { ...item, amount: value };
          }
        } else {
          return item;
        }
      });
      setCart(newCart);
    }
    setIsOpen(true);
  };

  // handle select
  const handleSelect = (e, id) => {
    const value = parseInt(e.target.value);
    const cartItem = cart.find((item) => {
      return item.id === id;
    });
    if (cartItem) {
      const newCart = [...cart].map((item) => {
        if (item.id === id) {
          setAmount(value);
          return { ...item, amount: value };
        } else {
          return item;
        }
      });
      setCart(newCart);
    }
  };

  // clear cart
  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        isOpen,
        setIsOpen,
        addToCart,
        removeFromCart,
        itemsAmount,
        handleInput,
        handleSelect,
        total,
        clearCart,
        error,
        setError,
        setCart,
        cart,
        amount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
