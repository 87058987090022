import React from 'react';
// components
import ProductSlider from './ProductSlider';
// useFetch hook
import useFetch from '../hooks/useFetch.js';

const LatestProducts = () => {
  // get new products
  const { data } = useFetch('/products');

    return (
    <div className='mb-16'>
      <div className='container mx-auto'>
        <h2 className='h2 mb-6 text-center xl:text-left'>НОВЫЕ ПОСТУПЛЕНИЯ</h2>
      </div>
      <ProductSlider data={data}/>
    </div>
  );
};

export default LatestProducts;
