import React from "react";
// link
import { NavLink } from "react-router-dom";
import useFetch from "../hooks/useFetch";

const CategoryNav = () => {
  const { data } = useFetch(
    `/categories`
  );
  
  return (
    <div className="w-full mb-10 sm:mt-6 mt-5 xl:mt-0 flex flex-col gap-5">
      <div className="uppercase w-max p-2 rounded lg:text-3xl text-xl ">
        КАТЕГОРИИ
      </div>
      <div className="flex sm:gap-8 gap-5 overflow-hidden overflow-x-auto p-2 custom-scrollbar">
        {data &&
          data.map((item) => {
            return (
              <div
                className="w-24 flex flex-col items-center lg:gap-6 sm:gap-4 gap-2"
                key={item.id}
              >
                <NavLink to={`/products/${item.id}`}>
                  <div className="lg:w-24 sm:w-20 w-16 lg:h-24 sm:h-20 h-16 rounded-full bg-accent flex justify-center items-center overflow-hidden">
                    <img
                      src={item.image}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </NavLink>
                <p className="text-center lg:text-[15px] lg:leading-[16px] sm:text-[13px] sm:leading-[14px] text-[11px] leading-[12px]">
                  {item.category_name}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryNav;

/* 
<aside className='hidden xl:flex'>
      <div className='bg-primary flex flex-col w-[286px] h-[600px] rounded-[8px] overflow-hidden'>
        <div className='bg-accent py-4 text-white uppercase font-semibold flex items-center justify-center'>
          КАТЕГОРИИ
        </div>
        <div className='flex flex-col gap-y-6 p-6'>
          {data.map((category) => {
            return (
              <Link
                to={`/products/${category.id}`}
                className='cursor-pointer uppercase'
                key={category.id}
              >
                {category.category_name}
              </Link>
            );
          })}
        </div>
      </div>
    </aside>
*/
