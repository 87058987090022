/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {FaYoutube, FaInstagram, FaTwitter, FaFacebook, FaTelegram} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='pt-16 bg-primary'>
      <div className='container mx-auto'>
        <div className='flex gap-x-6 max-w-max mx-auto text-lg mb-16'>
          <a href='https://www.instagram.com/maxphoto.uz/' className='hover:text-white transition-all'>
            <FaInstagram />
          </a>
          <a href='https://t.me/MaxPhotouz' className='hover:text-white transition-all'>
            <FaTelegram />
          </a>
        </div>
      </div>
      {/* copyright */}
      <div className='py-10 border-t border-t-white/10'>
        <div className='container mx-auto'>
          <div className='text-center text-sm text-white/60'>
            Copyright &copy; MaxPhoto 2024. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
