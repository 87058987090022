import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAxTgRI65TtxyXC0yAzMgbGEtl7TX6Qusg",
    authDomain: "maxphoto-fa554.firebaseapp.com",
    projectId: "maxphoto-fa554",
    storageBucket: "maxphoto-fa554.appspot.com",
    messagingSenderId: "1016711789044",
    appId: "1:1016711789044:web:cc2013babbf4fa973f21a1",
    measurementId: "G-GZD75F8JN5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };