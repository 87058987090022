/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import components
import CategoryNav from "./CategoryNav";
import MainSlider from "./MainSlider";
// images
import PromoImg1 from "../img/promo_img1.png";
import PromoImg2 from "../img/promo_img2.png";
import useFetch from "../hooks/useFetch";
import ThemedSuspense from "./ThemedSuspense";

const Hero = () => {
  const { data } = useFetch("/categories");

  if (!data) {
    return (
      <div>
        <ThemedSuspense />
      </div>
    );
  }

  return (
    <section className="mb-[30px] pt-24 lg:pt-0">
      <div className="container mx-auto">
        {/* sidebar */}
        <CategoryNav data={data} />
        <div className="flex flex-col gap-y-[30px] xl:flex-row xl:gap-x-[30px]">
          {/* main slider */}
          <div className="w-full max-w-lg lg:max-w-[734px] mx-auto">
            <MainSlider />
          </div>
          {/* promos */}
          <div className="flex flex-col gap-y-[30px] w-full max-w-lg mx-auto h-[600px]">
            {/* promo 1 */}
            <div className="grad flex-1 h-[250px] rounded-[8px] overflow-hidden relative p-6">
              {/* text */}
              <div className="flex flex-col max-w-[144px] h-full justify-center">
                <div className="text-[20px] uppercase font-medium leading-tight mb-4">
                  Сэкономьте 35% на всех зеркальных камерах
                </div>
                <a href="#" className="uppercase text-accent">
                  Купить сейчась
                </a>
              </div>
              {/* img */}
              <img
                className="absolute z-20 -top-2 -right-4"
                src={PromoImg1}
                alt=""
              />
            </div>
            {/* promo 2 */}
            <div className="grad flex-1 h-[250px] rounded-[8px] overflow-hidden relative p-6">
              {/* text */}
              <div className="flex flex-col max-w-[144px] h-full justify-center">
                <div className="text-[20px] uppercase font-medium leading-tight mb-4">
                  Скидка 25% на беззеркальные камеры
                </div>
                <a href="#" className="uppercase text-accent">
                  Купить сейчась
                </a>
              </div>
              {/* img */}
              <img
                className="absolute z-20 top-4 -right-6"
                src={PromoImg2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
