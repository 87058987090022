import React, {useEffect} from 'react';

import {createBrowserRouter, RouterProvider, Outlet} from 'react-router-dom';

// pages
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Search from './pages/Search';
import Order from "./pages/Order";

// components
import Header from './components/Header';
import Footer from './components/Footer';
import CategoryNav from "./components/CategoryNav";

//analytics
import { logEvent } from 'firebase/analytics';
import {analytics} from "./firebase";


// layout
const Layout = () => {
    useEffect(() => {
        logEvent(analytics, 'button_click', {
            button_name: 'example_button'
        });
        console.log('GA is fire!!!');
    }, []);

    return (
        <div>
            <Header/>
            {/*<CategoryNav/>*/}
            <Outlet/>
            <Footer/>
        </div>
    );
};

const router = createBrowserRouter([
    {
        element: <Layout/>,
        children: [
            {path: '/', element: <Home/>},
            {path: '/products/:id', element: <Products/>},
            {path: '/product/:id', element: <ProductDetails/>},
            {path: '/order', element: <Order/>},
            {path: '/search', element: <Search/>},
        ],
    },
]);

const App = () => {
    return (
        <div>
            <RouterProvider router={router}/>
        </div>
    );
};

export default App;
